import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/o-mnie',
    name: 'About',
    component: () => import('../views/About.vue')
  },
  {
    path: '/maksymy',
    name: 'Maxims',
    component: () => import('../views/Maxims.vue'),
    children: [
      {
        path: ':theme',
        name: 'MaximsBox',
        component: () => import('../components/MaximsBox.vue'),
      }
    ]
  },
  {
    path: '/wiersze',
    name: 'Poems',
    component: () => import('../views/Poems.vue'),
    children: [
      {
        path: ':theme',
        name: 'PoemsBox',
        component: () => import('../components/PoemsBox.vue'),
        children: [
          {
            path: ':poemLink',
            name: 'PoemPage',
            component: () => import('../components/PoemPage.vue'),
          }
        ]
      }
    ]
  },
  {
    path: '/ksiazki',
    name: 'Books',
    component: () => import('../views/Books.vue')
  },
  {
    path: '/zdjecia',
    name: 'Photos',
    component: () => import('../views/Photos.vue')
  },
  {
    path: '/dyskografia',
    name: 'Discography',
    component: () => import('../views/Discography.vue'),
  },
  {
    path: '/dyskografia/:albumLink',
    name: 'AlbumPage',
    component: () => import('../components/AlbumPage.vue'),
  },
  {
    path: '/kontakt',
    name: 'Contact',
    component: () => import('../views/Contact.vue')
  },
  {
    path: '*',
    name: 'PageNotFound',
    component: () => import('../views/PageNotFound.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to) {
    if (to.name === 'MaximsBox' || to.name === 'PoemsBox') {
      return  null
    }
    if (to.name === 'PoemPage') {
      return  null
    }
    else {
      return { x: 0, y: 0 }
    }
  }
})

export default router
