<template>
  <footer>
    <div class="footer-background"></div>
    <div class="footer-wrapper">
        <div class="title"><h1>{{ pageContent.header }}</h1></div>
            <router-link class="email" to="/kontakt">
                <div>
                    <svg class="icon-mail">
                        <path fill="currentColor" d="M22 6C22 4.9 21.1 4 20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6M20 6L12 11L4 6H20M20 18H4V8L12 13L20 8V18Z" />
                    </svg>
                </div>
            </router-link>
            <div class="social-icons">
                <a href="https://www.facebook.com/robertiwanowskiofficial/" target="_blank">
                    <svg class="icon-facebook">
                        <path fill="currentColor" d="M12 2.04C6.5 2.04 2 6.53 2 12.06C2 17.06 5.66 21.21 10.44 21.96V14.96H7.9V12.06H10.44V9.85C10.44 7.34 11.93 5.96 14.22 5.96C15.31 5.96 16.45 6.15 16.45 6.15V8.62H15.19C13.95 8.62 13.56 9.39 13.56 10.18V12.06H16.34L15.89 14.96H13.56V21.96A10 10 0 0 0 22 12.06C22 6.53 17.5 2.04 12 2.04Z" />
                    </svg>
                </a>
                <a href="https://www.instagram.com/robertiwanowskiofficial/" target="_blank">
                    <svg class="icon-instagram">
                        <path fill="currentColor" d="M7.8,2H16.2C19.4,2 22,4.6 22,7.8V16.2A5.8,5.8 0 0,1 16.2,22H7.8C4.6,22 2,19.4 2,16.2V7.8A5.8,5.8 0 0,1 7.8,2M7.6,4A3.6,3.6 0 0,0 4,7.6V16.4C4,18.39 5.61,20 7.6,20H16.4A3.6,3.6 0 0,0 20,16.4V7.6C20,5.61 18.39,4 16.4,4H7.6M17.25,5.5A1.25,1.25 0 0,1 18.5,6.75A1.25,1.25 0 0,1 17.25,8A1.25,1.25 0 0,1 16,6.75A1.25,1.25 0 0,1 17.25,5.5M12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9Z" />
                    </svg>
                </a>
            </div>
            <div class="site-map">
                <div class="links-container">
                    <router-link
                        v-for="(link, index) in links" :key="index"
                        :to="link.path"><span>{{ link.name }}</span>
                    </router-link>
                </div>
            </div>
            <div class="copyright">Robert Iwanowski Copyright &copy; 2019 - {{ year }}</div>
        </div>
  </footer>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'Navbar',
  props: ['scrolled'],
  data: () => ({

  }),
  computed: {
      ...mapState({
          pageContent: state => state.data.footer,
          year: state => state.year
        }),
      ...mapGetters(['links'])
  }
}
</script>

<style scoped lang="scss">

footer {
    height: 436px;
    margin-top: 100px;
    .footer-background {
        position: absolute;
        background-color: #EB7054;
        box-shadow: 0 -2px 5px 0px #bbb;
        height: 436px;
        width: 100%;
        z-index: 1;
    }
    .footer-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 370px;
        width: 100%;
        padding-top: 50px;
        color: white;
        text-align: center;
        z-index: 2;
        .title {
            z-index: 2;
            h1 {
                font-family: 'Cormorant Garamond', serif;
                font-weight: 300;
                @media screen and (min-width: 600px) {
                    font-size: 4rem;
                }
            }
        }
        .email {
            z-index: 2;
            margin: 0 auto;
            color: white;
            .icon-mail {
                width: 70px;
                height: 60px;
                transition: .25s;
                path {
                    transform: scale(3);
                }
                &:hover {
                    cursor: pointer;
                    transform: rotate(15deg);
                }
            }
        }
        .copyright {
            z-index: 2;
            margin: 15px 0;
        }
    }
    .social-icons {
        z-index: 2;
        display: flex;
        justify-content: center;
        align-items: center;
        a {
            margin: 0 2px;
            color: white;
            .icon-facebook, .icon-instagram {
                width: 25px;
                height: 25px;
                margin: 15px 20px;
                transform: scale(2);
                transition: 0.75s;
            }
            @media screen and (min-width: 600px) {
                margin: 0 5px;
            }
        }
        .icon-facebook {
            &:hover {
                color: #3b5998;
            }
        }
        .icon-twitter {
            &:hover {
                color: #00aced;
            }
        }
        .icon-youtube {
            &:hover {
                color: #cc181e;
            }
        }
        .icon-instagram {
            &:hover {
                color: #c13584;
            }
        }
    }
    .site-map {
        z-index: 2;
        .links-container {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            list-style-type: none;
            a {
                color: white;
                //border-right: 1px solid white;
                span {
                    padding: 5px 10px;
                    &:hover {
                        background-color: white;
                        color: #EB7054;
                        transition: 1s;
                    }
                }
            }
        }
    }
}

</style>
