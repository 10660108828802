<template>
  <div id="app">

    <transition name="fade">
      <div v-if="!isPageLoaded || !isDataLoaded" class="loading-bg">
        <img :src="preloader" alt="Ładowanie strony płyta winylowa">
      </div>
    </transition>

    <Navbar v-if="isPageLoaded && isDataLoaded" :scrolled="scrolled"></Navbar>

    <main
      v-if="isPageLoaded && isDataLoaded"
      :style="mobileNav ? {marginTop: '70px'} : {marginTop: '110px'}"
    >
      <transition name="fade" mode="out-in">
        <router-view :posY="posY" />
      </transition>
    </main>
    
    <Footer v-if="isPageLoaded && isDataLoaded"></Footer>

    <transition name="fade">
      <div
        class="arrow-container"
        v-if="scrolled"
        @click="scrollTop()"
      >
        <div></div>
          <div class="top-arrow"><p>&#11165;</p></div>
        <div></div>
      </div>
    </transition>

  </div>
</template>

<script>
import Navbar from '@/components/Navbar'
import Footer from '@/components/Footer'

export default {
  name: 'App',
  components: {
    Navbar, Footer
  },
  data: () => ({
    preloader: require('@/assets/loading.gif'),
    isPageLoaded: false,
    scrolled: false,
    posY: 0
  }),
  computed: {
    mobileNav () {
        return this.$store.getters.mobileNav
    },
    isDataLoaded () {
      return this.$store.state.dataLoaded === 5 ? true : false
    }
  },
  methods: {
    pageLoaded () {
      this.isPageLoaded = true
      document.body.style.overflowY = 'visible'
    },
    setScroll () {
      var posY = window.scrollY
      this.posY = posY
      if (posY > 0) {
        this.scrolled = true
      }
      else {
        this.scrolled = false
      }
    },
    scrollTop () {
      window.scrollTo({top: 0, behavior: 'smooth'})
    },
    setWindowWidth () {
      this.$store.dispatch('getWindowWidth')
    }
  },
  mounted () {
    this.$store.dispatch('getItems', {fileName: 'data.json', itemsName: 'Data'})
    this.$store.dispatch('getItems', {fileName: 'wiersze.json', itemsName: 'Poems'})
    this.$store.dispatch('getItems', {fileName: 'maksymy.json', itemsName: 'Maxims'})
    this.$store.dispatch('getItems', {fileName: 'dyskografia.json', itemsName: 'Discography'})
    this.$store.dispatch('getItems', {fileName: 'zdjecia.json', itemsName: 'Photos'})
    this.$store.dispatch('getYear')
    //this.$store.dispatch('getData')
    window.addEventListener('load', this.pageLoaded)
  },
  created () {
    window.addEventListener('scroll', this.setScroll)
    window.addEventListener('resize', this.setWindowWidth)
  }
}
</script>

<style lang="scss">
* {
    margin: 0;
    padding: 0;
    border: 0;
    line-height: 1.4em;
    vertical-align: baseline;
    text-decoration: none;
    outline: 0;
    box-sizing: border-box;
}
a, a:hover, a:visited {
    color: black;
    text-decoration: none;
}
html {
    height: 100vh;
    font-size: 62.5%;
}
body {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    position: relative;
    overflow-y: hidden;
    overflow-x: hidden;
}
.loading-bg {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: white;
  z-index: 1000;
}
.arrow-container {
    position: fixed;
    right: 15px;
    bottom: 25px;
    z-index: 9;
    @media screen and (min-width: 1024px) {
      right: 30px;
      bottom: 50px;
    }
    .top-arrow {
        float: right;
        width: 36px;
        height: 36px;
        background-color: white;
        border: 2px solid #EB7054;
        color: #EB7054;
        font-size: 40px;
        font-weight: 400;
        text-align: center;
        transition: 0.75s;
        z-index: 9;
        &:hover {
            cursor: pointer;
        }
        @media screen and (min-width: 1024px) {
            &:hover {
                color: #FFFFFF;
                background-color: #EB7054;
            }
        }
        p {
            display: inline-block;
            height: 0px;
            margin: 0 auto;
            transform: translate(-2px, -12px);
        }
    }
}
.subpage {
    margin-top: 0px;
     @media screen and (min-width: 700px) {
       margin-top: 100px;
     }
     @media screen and (min-width: 1200px) {
       margin-top: 200px;
     }
}
.router-link-exact-active, .router-link-active {
  background-color: #EB7054;
  color: white !important;
  span {
    background-color: #EB7054;
    color: white !important;
  }
}
.btn-primary {
  background-color: #EB7054;
  color: white !important;
  margin: 5px;
  padding: 10px;
  font-family: 'PT Serif', serif;
  letter-spacing: 3px;
  text-transform: uppercase;
  transition: .25s linear;
  &:hover {
    cursor: pointer;
    background-color: white;
    color: #EB7054 !important;
  }
}

.fade-enter-active, .fade-leave-active {
  transition: all .5s ease-in-out;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
.slide-right-enter-active, .slide-right-leave-active {
  transition: all .3s ease;
}
.slide-right-enter, .slide-right-leave-to {
  transform: translateX(-350px);
}
.slide-down-enter-active, .slide-down-leave-active {
  transition: all .5s ease-in-out;
  max-height: 100vh;
  overflow: hidden;
}
.slide-down-enter, .slide-down-leave-to {
  overflow: hidden;
   max-height: 0;
}

</style>
