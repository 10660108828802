<template>
  <section v-if="boxContent">
    <div class="first-container">
        <div class="first-element-wrapper" ref="first">
            <div
                class="section-photo photo-sm"
                :style="{backgroundImage: 'url(' + boxContent[0].photo + ')'}"
            ></div>
            <div class="section-text frame">
                <h1>{{ boxContent[0].header }}</h1>
                <p v-html="boxContent[0].text.replace(/\n/g,'<br>')"></p>
            </div>
        </div>
        <div class="second-element-wrapper" ref="second">
            <div
                class="section-photo photo-sm"
                :style="{backgroundImage: 'url(' + boxContent[1].photo + ')'}"
            ></div>
            <div class="section-text frame">
                <h1>{{ boxContent[1].header }}</h1>
                <p v-html="boxContent[1].text.replace(/\n/g,'<br>')"></p>
            </div>
        </div>
    </div>
    <div class="second-container">
        <div class="third-element-wrapper" ref="third">
            <div
                class="section-photo photo-sm"
                :style="{backgroundImage: 'url(' + boxContent[2].photo + ')'}"
            ></div>
            <div class="section-text frame">
                <h1>{{ boxContent[2].header }}</h1>
                <p v-html="boxContent[2].text.replace(/\n/g,'<br>')"></p>
            </div>
        </div>
    </div>
  </section>
</template>

<script>

export default {
  name: 'FrameBox',
  props: ['posY', 'boxContent'],
  data: () => ({
    
  }),
  computed: {
    
  },
  methods: {
    checkFrame () {
      if(this.$refs.first) {
        var y = this.$refs.first.offsetTop
        if (this.posY >= y - window.innerHeight / 2) {
          this.showFrame()
        }
      }
    },
    showFrame () {
      this.$refs.first.style.opacity = 1
      this.$refs.first.style.transform = 'translateX(0)'
      this.$refs.second.style.opacity = 1
      this.$refs.second.style.transform = 'translateX(0)'
      this.$refs.third.style.opacity = 1
      this.$refs.third.style.transform = 'translateX(0)'
    }
  },
  created () {
    window.addEventListener('scroll', this.checkFrame)
  }
}
</script>

<style scoped lang="scss">

section {
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    margin: 100px auto 0 auto;
    transition: 3s;
    .first-container {
        display: flex;
        flex-direction: column;
        .first-element-wrapper {
            opacity: 1;
            //transform: translate(-200px, 0);
            transform: translate(0, 0);
            transition: 3s;
            display: flex;
            flex-direction: column;
            @media screen and (min-width: 600px) {
                flex-direction: row;
                margin: 0 0 20px 0;
            }
            @media screen and (min-width: 960px) {
                .section-text {
                    flex-basis: 400px;
                    height: 220px;
                    margin: 15px 0 0 -75px;
                    padding: 30px 50px 0 100px;
                }
            }
            @media screen and (min-width: 1200px) {
                .section-text {
                    flex-basis: 500px;
                    height: 220px;
                    margin: 30px 0 0 -75px;
                    padding: 30px 50px 0 100px;
                }
            }
        }
        .second-element-wrapper {
            margin: 20px 0 0 0;
            opacity: 1;
            //transform: translate(200px, 0);
            transform: translate(0, 0);
            transition: 3s;
            display: flex;
            flex-direction: column;
            @media screen and (min-width: 600px) {
                flex-direction: row;
                margin: 0 0 20px 0;
            }
            @media screen and (min-width: 960px) {
                flex-direction: row-reverse;
                margin: 50px 0 0 0;
                .section-text {
                    flex-basis: 400px;
                    height: 220px;
                    margin: 15px -75px 0 0;
                    padding: 30px 100px 0 50px;
                }
            }
            @media screen and (min-width: 1200px) {
                flex-direction: row-reverse;
                margin: 50px 0 0 0;
                .section-text {
                    flex-basis: 500px;
                    height: 220px;
                    margin: 30px -75px 0 0;
                    padding: 30px 100px 0 50px;
                }
            }
        }
        @media screen and (min-width: 960px) {
            flex-basis: 66.66%;
            margin: 0 10px 0 15px;
        }
        @media screen and (min-width: 1200px) {
            flex-basis: 66.66%;
            margin: 0 10px 0 0;
        }
    }
    .second-container {
        .third-element-wrapper {
            margin: 20px 0 0 0;
            opacity: 1;
            //transform: translate(0, -200px);
            transform: translate(0, 0);
            transition: 3s;
            display: flex;
            flex-direction: column;
            @media screen and (min-width: 600px) {
                flex-direction: row;
                margin: 0 0 20px 0;
            }
            @media screen and (min-width: 960px) {
                flex-direction: column;
                height: 700px;
                .section-text {
                    flex-basis: 250px;
                    width: 250px;
                    margin: -75px 50px 0 20px;
                    padding: 80px 50px 30px 50px;
                }
            }
            @media screen and (min-width: 1200px) {
                flex-direction: column;
                height: 700px;
                .section-text {
                    flex-basis: 250px;
                    width: 300px;
                    margin: -75px 50px 0 20px;
                    padding: 80px 50px 30px 50px;
                }
            }
        }
        @media screen and (min-width: 960px) {
            flex-basis: 33.33%;
            margin-left: 10px;
        }
    }
    @media screen and (min-width: 960px) {
        flex-direction: row;
    }
}
.photo-sm {
    height: 200px;
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    @media screen and (min-width: 600px) {
        flex-basis: 50%;
        height: 300px;
    }
    @media screen and (min-width: 960px) {
        max-width: 300px;
        max-height: 250px;
    }
    @media screen and (min-width: 1200px) {
        max-width: 370px;
        max-height: 300px;
    }
}
.frame {
  margin: -15px 5px 0 5px;
  padding: 25px;
  border: 1px solid #444;
  z-index: 1;
  h1 {
      font-family: 'Cormorant Garamond', serif;
      font-size: 3rem;
      letter-spacing: 1px;
      color: #333;
  }
  P {
      margin: 15px 0 20px 0;
      font-size: 1.3rem;
      line-height: 2.8rem;
      color: #666;
  }
  span {
      display: inline-block;
      padding: 10px 25px;
      font-size: 1.2rem;
      color: #333333;
      transition: 1s;
      text-transform: uppercase;
      &.active, &:hover {
          background-color: #EB7054;
          color: #FFFFFF;
      }
  }
  @media screen and (min-width: 600px) {
      flex-basis: 50%;
      margin: 0 0 0 5px;
  }
  @media screen and (min-width: 960px) {
      flex-basis: 627px;
      margin: 75px 0 75px -50px;
      padding-left: 100px;
  }
  @media screen and (min-width: 1400px) {
      h1 {
          font-size: 4rem;
          letter-spacing: 2px;
      }
      p {
          font-size: 1.4rem;
      }
  }
}
h1 {
    font-family: 'Cormorant Garamond', serif;
    font-size: 3rem;
    letter-spacing: 1px;
    color: #333;
    font-weight: 300;
    @media screen and (min-width: 1400px) {
        font-size: 4rem;
        letter-spacing: 2px;
    }
}

</style>
