<template>
  <div>

    <div id="menu-panel-container" :style="{height: navHeight}">
        <div
            id="mobile-panel-button"
            @click="openMobileNav()"
        >
            <div class="bar1"></div>
            <div class="bar2"></div>
            <div class="bar3"></div>
        </div>
        <a href="http://robertiwanowski.pl"><div class="logo">Robert <span>Iwanowski</span></div></a>
        <nav v-if="!mobileNav" class="nav">
            <router-link
                :exact="$route.path === '/' ? false : true"
                v-for="(link, index) in links" :key="index"
                :to="link.path">
                <span
                    :class="[
                        (link.id == 'maxims' && $route.name == 'MaximsBox' ? 'active-link' : null),
                        (link.id == 'poems' && $route.name == 'PoemsBox' ? 'active-link' : null)
                    ]"
                >{{ link.name }}</span>
            </router-link>
            <div class="language-btn"><a href="http://robertiwanowski.com"><img src="@/assets/gb.png"></a></div>
        </nav>
    </div>

    <transition name="fade">
        <div
            v-if="isMobileNavOpen"
            class="mobile-nav-bg"
            @click="closeMobileNav()"
        ></div>
    </transition>
    <transition name="slide-right">
        <div
            v-if="isMobileNavOpen"
            class="mobile-nav"
        >
            <div class="mobile-logo-container">
                <div class="logo logo-mobile">Robert <span>Iwanowski</span></div>
            </div>
            <nav v-if="mobileNav" class="mobile-nav-links">
                <router-link
                    :exact="$route.path === '/' ? false : true"
                    v-for="(link, index) in links" :key="index"
                    :to="link.path"
                    @click.native="closeMobileNav()">
                    <span
                        :class="[
                            (link.id == 'maxims' && $route.name == 'MaximsBox' ? 'active-link' : null),
                            (link.id == 'poems' && $route.name == 'PoemsBox' ? 'active-link' : null)
                        ]"
                    >{{ link.name }}</span>
                </router-link>
                <div class="mobile-language-btn"><a href="http://robertiwanowski.com"><img src="@/assets/gb.png"></a></div>
            </nav>
            <div
                class="close-mobile-nav-btn"
                @click="closeMobileNav()"
            ><p>&#11164;</p></div>
        </div>
    </transition>
    
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Navbar',
  props: ['scrolled'],
  data: () => ({
      isMobileNavOpen: false
  }),
  computed: {
      ...mapGetters(['links']),
      mobileNav () {
          return this.$store.getters.mobileNav
      },
      navHeight () {
          return this.scrolled || this.mobileNav ? '70px' : '110px'
      }
  },
  methods: {
      openMobileNav () {
          this.isMobileNavOpen = true
          document.body.style.overflowY = 'hidden'
      },
      closeMobileNav () {
          this.isMobileNavOpen = false
          document.body.style.overflowY = 'visible'
      }
  }
}
</script>

<style scoped lang="scss">
#menu-panel-container {
    position: fixed;
    width: 100%;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 55px;
    box-shadow: 0 2px 5px 0px #bbb;
    background-color: white;
    transition: 0.5s;
    z-index: 10;
    a {
        display: contents;
    }
    @media screen and (min-width: 1200px) {
        justify-content: space-between;
    }
}
#mobile-panel-button {
    position: fixed;
    top: 15px;
    left: 15px;
    display: inline-block;
    .bar1, .bar2, .bar3 {
        width: 35px;
        height: 5px;
        background-color: #333;
        margin: 6px 0;
        transition: 0.4s;
        border-radius: 3px;
    }
    .change.bar1 {
        transform: rotate(-45deg) translate(-8px, 7px);
    }
    .change.bar2 {
        opacity: 0;
    }
    .change.bar3 {
        transform: rotate(45deg) translate(-8px, -8px);
    }
    &.change {
        transform: rotate(-90deg);
    }
    &:hover {
        cursor: pointer;
    }
    @media screen and (min-width: 1200px) {
        display: none;
    }
}
#mobile-logo-container {
    height: 100px;
    margin-top: 50px;
    background-color: white;
    @media screen and (min-width: 600px) {
        display: none;
    }
}
.logo {
    display: inline-block;
    transform: scale(.8);
    margin-left: 30px;
    padding: 10px;
    font-family: 'Josefin Slab', serif;
    color: #EB7054;
    font-size: 2rem;
    border: 1px solid #444;
    white-space: nowrap;
    transition: 0.5s;
    &:before {
        content: '';
        position: absolute;
        width: 50%;
        height: 100%;
        transform: translate(-38%, -40%);
        border: 1px solid #444;
    }
    span {
        font-weight: 700;
    }
    @media screen and (min-width: 600px) {
        margin-left: 5%;
        margin-top: 5px;
    }
    @media screen and (min-width: 1024px) {
        margin-left: 5%;
    }
    @media screen and (min-width: 1200px) {
        margin-left: 10%;
        transform: scale(1);
    }
}
.logo-mobile {
    margin: 30px auto;
}
.mobile-logo-container {
    display: flex;
    justify-content: center;
}
nav.nav {
    display: flex;
    span {
        display: block;
        margin: 5px 5px;
        padding: 10px;
        font-family: 'PT Serif', serif;
        font-size: 1.2rem;
        letter-spacing: 3px;
        color: #333333;
        transition: all .75s;
        text-transform: uppercase;
        &:hover {
            background-color: #EB7054;
            color: #FFFFFF;
        }
    }
}
.language-btn {
  display: none;
  width: 40px;
  margin: 13px 30px 0 30px;
  @media screen and (min-width: 1200px) {
        display: block;
    }
}
.mobile-language-btn {
    display: block;
    margin: 20px 0 0 50px;
}
.mobile-nav-bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,.5);
    z-index: 100;
}
.mobile-nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 300px;
    height: 100vh;
    background-color: white;
    z-index: 101;
    box-shadow: 3px 2px 10px 2px #555;
}
.close-mobile-nav-btn {
    width: 40px;
    height: 70px;
    font-size: 4rem;
    color: #444;
    position: fixed;
    top: 6px;
    left: 250px;
    &:hover {
        cursor: pointer;
    }
}
nav.mobile-nav-links {
    display: flex;
    flex-direction: column;
    span {
        display: block;
        margin-left: 25px;
        padding: 10px 25px;
        font-size: 1.6rem;
        color: #333333;
        transition: 1s;
        text-transform: uppercase;
        &.active, &:hover {
            background-color: #EB7054;
            color: #FFFFFF;
        }
    }
}
.active-link {
  background-color: #EB7054;
  color: white !important;
}
</style>
