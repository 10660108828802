<template>
  <div>
    
    <FrameLG
        :posY="posY"
        :photo="pageContent.main.photo"
        :header="pageContent.main.header"
    ></FrameLG>
    
    <FrameMD
        :posY="posY"
        :transitionPhoto="false"
        :staticPhoto="true"
        :photo="pageContent.section0.photo"
        :photoHeight="'auto'"
        :header="pageContent.section0.header"
        :text="pageContent.section0.text"
    ></FrameMD>

    <FrameBox
        :posY="posY"
        :boxContent="pageContent.section1"
    ></FrameBox>
    
    <FrameMDReverse
        :posY="posY"
        :transitionPhoto="false"
        :staticPhoto="true"
        :photo="pageContent.section2.photo"
        :photoHeight="'500px'"
        :header="pageContent.section2.header"
        :text="pageContent.section2.text"
        :style="{marginTop: '50px'}"
    ></FrameMDReverse>

  </div>
</template>

<script>
import { mapState } from 'vuex'
import FrameBox from '@/components/FrameBox'
import FrameLG from '@/components/FrameLG'
import FrameMD from '@/components/FrameMD'
import FrameMDReverse from '@/components/FrameMDReverse'

export default {
  name: 'Home',
  props: ['posY'],
  components: {FrameBox, FrameLG, FrameMD, FrameMDReverse},
  data () {
      return {

      }
  },
  computed: {
    ...mapState({pageContent: state => state.data.home})
  }
}

</script>

<style lang="scss" scoped>



</style>